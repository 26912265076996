/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { PageLayout } from '../components/Layout';
import { Sidebar } from '../components/Sidebar';
import { jsx } from 'theme-ui';
import Img from 'gatsby-image';
import { SEO } from '../components/SEO';
import { CodeHighlight } from '../components/CodeHighlight';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Category } from '../components/Category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Tag } from '../components/Tag';
import utc from 'dayjs/plugin/utc';
import { AboutAuthorWidget } from '../components/AboutAuthorWidget';
dayjs.extend(utc);

interface Props {
  pageContext: any;
}
const ArticlePost = ({ pageContext }: Props) => {
  const {
    title,
    date,
    category,
    tags,
    featuredImage,
    body,
    author,
    excerpt,
  } = pageContext.article;
  //  const { nodes } = data.allMdx;
  // const BeforeArticleContent = nodes.filter(
  //   (node: any) => node.frontmatter.location === 'BeforeArticleContent'
  // );

  const { BeforeArticleContent, articleAuthor } = pageContext;

  let columnLayout = '100px 1fr';

  if (!articleAuthor[0].profilePicture) {
    columnLayout = '1fr';
  }

  return (
    <React.Fragment>
      <SEO
        title={title}
        description={excerpt}
        ogImage={featuredImage ? featuredImage.src : ''}
      />
      <PageLayout>
        {BeforeArticleContent[0].enabled && (
          <div
            sx={{
              margin: 'auto',
              maxWidth: '1060px',
              padding: '0 10px',
              marginBottom: '30px',
            }}
          >
            <MDXProvider components={{}}>
              <MDXRenderer>{BeforeArticleContent[0].body}</MDXRenderer>
            </MDXProvider>
          </div>
        )}
        {featuredImage && (
          <div
            sx={{
              height: '400px',
              marginBottom: '30px',
            }}
          >
            <Img
              fluid={featuredImage}
              sx={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                border: 'solid 1px',
                borderColor: 'lightBorder',
              }}
            />
          </div>
        )}

        <div
          sx={{
            display: 'grid',
            gap: '30px',
            padding: '0 10px',
            gridTemplateColumns: ['1fr', '2fr 1fr'],
          }}
        >
          <article>
            <MDXProvider components={{ code: CodeHighlight }}>
              <header
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  marginBottom: '50px',
                  borderBottom: 'solid 4px',
                  paddingBottom: '20px',
                  borderColor: 'primary',
                }}
              >
                <div>
                  <Link
                    to={`${pageContext.basePath}/category/${_.kebabCase(
                      category
                    )}`}
                  >
                    <Category>{category}</Category>
                  </Link>
                </div>
                <h1
                  sx={{
                    fontSize: '48px',
                    lineHeight: '1',
                    margin: '15px 0',
                  }}
                >
                  {title}
                </h1>
                <div>
                  <time>{dayjs.utc(date).format('MM/DD/YYYY')}</time>
                  {' | '}
                  <span sx={{ fontWeight: '600' }}>{author}</span>
                </div>
              </header>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
            {tags && (
              <div sx={{ marginTop: '40px' }}>
                <FontAwesomeIcon icon={faTags} sx={{ marginRight: '10px' }} />

                {tags.map((tag: any) => (
                  <Link
                    key={tag}
                    to={`${pageContext.basePath}/tag/${_.kebabCase(tag)}`}
                  >
                    <Tag>{tag.trim()}</Tag>
                  </Link>
                ))}
              </div>
            )}
          </article>
          <div>
            <AboutAuthorWidget
              sx={{ marginBottom: '30px' }}
              author={articleAuthor[0]}
            />
            <Sidebar />
          </div>
        </div>
      </PageLayout>
    </React.Fragment>
  );
};

export default ArticlePost;
